import { render, staticRenderFns } from "./DataManageMonth.vue?vue&type=template&id=38504761&scoped=true"
import script from "./DataManageMonth.vue?vue&type=script&lang=js"
export * from "./DataManageMonth.vue?vue&type=script&lang=js"
import style0 from "./DataManageMonth.vue?vue&type=style&index=0&id=38504761&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38504761",
  null
  
)

export default component.exports